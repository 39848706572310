import React from "react"
import styled from "styled-components"

export const GlassCard = styled.div`
  background-color: #c7d2fe66;
  background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.4));
  backdrop-filter: blur(7px);
  box-shadow: 10px 10px 10px rgba(30, 30, 30, 0.1);
  border-radius: 20px;
  width: 100%;
  height: auto;
  max-width: 400px;
  padding: 20px;
  margin: 20px;
  text-align: center;
`
const Card = () => {
  return (
    <GlassCard>
      Hi! I'm a frontend engineer based in Los Angeles. I enjoy building
      well-crafted UI and develop apps primarily using React and Typescript.
    </GlassCard>
  )
}

export default Card
