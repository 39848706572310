import styled, { createGlobalStyle } from "styled-components"
import BGImage from "./images/archbg.jpeg"

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html {
    height: 100%;
  }

  body {
    background-image: url(${BGImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    margin: 0;
    padding: 50px 20px 100px;
    display: flex;
    justify-content: center;
    font-family: Lato;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h1 {
    color: black;
    font-size: 20px;
    margin-bottom: 0px;
  }

  h3 {
    font-size: 14px;
    font-weight: 400;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  input,
  button {
    display: ${({ hasIcon }) => (hasIcon ? "flex" : "block")};
    align-items: center;
    cursor: pointer;
    width: 100%;
    max-width: 400px;
    min-height: 65px;
    margin: 16px 0;
    border-radius: 36px;
    background-color: rgba(0, 0, 0, 0);
    color: rgb(255, 255, 255);
    box-shadow: none;
    outline: none;
    font-size: 15px;
    border: 2px solid rgb(255, 255, 255);
    transition: all 0.3s ease;

    p {
      flex: ${({ hasIcon }) => (hasIcon ? "2" : "1")};
      padding-right: ${({ hasIcon }) => (hasIcon ? "55px" : "0")};
    }

    :hover {
      background-color: rgba(255, 255, 255, 0.733);
      p {
        color: #000;
      }
    }
  }
`
