import styled from "styled-components";
import React from "react";
import { ButtonWrapper } from "../App.styles";

const LinkIcon = styled.img`
  color: white;
  width: 58px;
  height: 58px;
`;

const Button = ({ title, link }) => {
  //   let image = "https://cdn.beacons.ai/images/social_icons/amazon_padded.png";
  let image = false;
  console.log("image.length", image ? true : false);
  return (
    <ButtonWrapper hasIcon={image ? true : false}>
      <button>
        {image && <LinkIcon src={image} />}
        <p>{title}</p>
      </button>
    </ButtonWrapper>
  );
};

export default Button;
