import React from "react"
import styled from "styled-components"
import { Wrapper } from "../App.styles"
import { ReactComponent as TikTokLogo } from "../images/tik-tok.svg"
import InstagramIcon from "@material-ui/icons/Instagram"
import TwitterIcon from "@material-ui/icons/Twitter"
import YouTubeIcon from "@material-ui/icons/YouTube"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import GitHubIcon from "@mui/icons-material/GitHub"
import EmailIcon from "@mui/icons-material/Email"

const ProfilePic = styled.img`
  width: 100px;
  height: auto;
  border-radius: 50%;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
`
const SocialIcons = styled.div`
  display: flex;
  flex-direction: row;
  svg {
    width: 28px;
    height: 28px;
    margin: 6px;
    cursor: pointer;
  }
`
const IconLink = styled.a`
  outline: none;
  color: black;
`

const Bio = ({ user }) => {
  const { name, email, picture } = user
  const { first, last } = name
  const { large } = picture
  console.log("picture", large)
  const mypic = "https://avatars.githubusercontent.com/u/8499727?v=4"
  return (
    <Wrapper>
      <ProfilePic src={mypic}></ProfilePic>
      <h1>@ooridoori</h1>
      {/* <h3>
        {first} {last} {email}{" "}
      </h3> */}
      <h3>Nancy Kim</h3>
      <SocialIcons>
        {/* <TikTokLogo />
        <InstagramIcon />
        <TwitterIcon />
        <YouTubeIcon /> */}
        <IconLink
          href="https://www.linkedin.com/in/nancy-kim-85b5408a/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedInIcon />
        </IconLink>
        <IconLink
          href="https://github.com/ooridoori"
          target="_blank"
          rel="noopener noreferrer"
        >
          <GitHubIcon />
        </IconLink>
        <IconLink href="mailto:nancyffssrs@gmail.com">
          <EmailIcon />
        </IconLink>
      </SocialIcons>
    </Wrapper>
  )
}

export default Bio
