import React, { useEffect, useState } from "react"
import { GlobalStyle, Wrapper, ButtonWrapper } from "./App.styles"
import axios from "axios"
import Bio from "./components/Bio"
import Card from "./components/Card"
import Button from "./components/Button"
import Form from "./components/Form"
function App() {
  const [user, setUserData] = useState(null)
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const URL = "https://randomuser.me/api"
        await axios
          .get(URL)
          .then(response => setUserData(response.data.results[0]))
      } catch (e) {
        console.log("e", e)
        setUserData(null)
      }
    }
    fetchUser()
  }, [])
  const buttonData = [
    {
      title: "myblog.io",
      URL: "",
      id: 1,
    },
    {
      title: "My Amazon Favorites",
      URL: "",
      id: 2,
    },
    {
      title: "Some other site",
      URL: "",
      id: 3,
    },
  ]

  const renderButtons = () => {
    return buttonData.map(data => (
      <Button title={data.title} link={data.URL} key={data.id} />
    ))
  }
  return (
    <>
      <GlobalStyle />
      <Wrapper>
        {user && <Bio user={user} />}
        <Card />
        {/* {renderButtons()} */}
        {/* <Form /> */}
      </Wrapper>
    </>
  )
}

export default App
